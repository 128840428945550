@mixin darken_buttons($color) {
  #generator {
    background: darken($color, 3);

    button {
      background: darken($color, 6);
    }
  }
}

@mixin lighten_buttons($color) {
  #generator {
    background: lighten($color, 3);

    button {
      background: lighten($color, 6);
    }
  }
}
