$white: #fff;
$black: #333;
$leaf_green: #7ac143;
$red: #f96167;
$yellow: #fce77d;
$purple: #3d155f;
$pink: #df678c;
$blue: #3642c6;
$lime: #ccf381;
$pale: #fbeaeb;
$navy: #2f3c7e;
$turquoise: #8bd8bd;

body {
  &.base-color {
    background: $white;
    color: $black;

    @include darken_buttons($white)
  }

  &.leaf-color {
    background: $leaf_green;
    color: $white;

    @include lighten_buttons($leaf_green)
  }

  &.red-yellow {
    background: $yellow;
    color: $red;

    @include lighten_buttons($yellow)
  }

  &.yellow-red {
    background: $red;
    color: $yellow;

    @include lighten_buttons($red)
  }

  &.purple-pink {
    background: $purple;
    color: $pink;

    @include lighten_buttons($purple)
  }

  &.blue-lime {
    background: $blue;
    color: $lime;

    @include darken_buttons($blue)
  }

  &.leaf-lime {
    background: $leaf_green;
    color: $lime;

    @include lighten_buttons($leaf_green)
  }

  &.pale-navy {
    background: $pale;
    color: $navy;

    @include lighten_buttons($pale)
  }

  &.navy-turquoise {
    background: $navy;
    color: $turquoise;

    @include darken_buttons($navy)
  }

}
